<template>
  <div
      class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--pulse-spin">
      <div class="ph-inpage-widget__body">
        <div class="ph-inpage-widget__message">
          <div class="ph-inpage-widget__desc ph-inpage-widget__txt-overflow-2">{{ adText }}</div>
          <div class="ph-inpage-widget__triangle2"></div>
        </div>
        <div class="ph-inpage-widget__circle">
          <div class="ph-inpage-widget__circle-thumb">
            <img v-show="!imgIcon" :src="require('@/assets/panel/images/inpage/empty-white.svg')" alt="">
            <img :src="imgIcon" v-show="imgIcon" alt="">
          </div>
          <div class="ph-inpage-widget__circle-notification">1</div>
          <div class="ph-inpage-widget__circle-border-2"></div>
          <div class="ph-inpage-widget__circle-border"></div>
          <div class="ph-inpage-widget__close">&#10005;</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>